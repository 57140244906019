/* GOOGLE DFP
-------------------------------------------------------------- */

.wrapper-main {
    width: 100%;
}

.google-ads {
    padding: 24px 0;
    max-width: 1920px !important;
    height: 242px !important;

    @media #{$media-xs} {
        padding: 12px 0;
        max-width: 375px !important;
        height: 124px !important;
    }

    div {
        width: 100% !important;
    }
}

.dfpAd,
.dfpImgAd {
    text-align: center;

    @media #{$media-xs} {
        margin: 0 auto !important;
    }

    > div {
        display: inline-block;

        &:empty {
            display: none;
        }
    }

    iframe {
        max-width: 100%;
    }
}

.dfpImgAd img {
    width: 100%;
    height: auto;
}

.ads-extrabox {
    &.is-sticky {
        position: absolute;
        top: 0;
    }
}


/* NAVIBOARD
-------------------------------------------------------------- */

#div-gpt-ad-NAVIBOARD-0 {
    display: block;

    > div {
        margin-top: 0;
        margin-bottom: 0;

        @media #{$media-to-sm} {
            margin-bottom: 20px;
        }
        @media #{$media-sm} {
            margin-left: -15px;
            margin-right: -15px;
        }
        @media #{$media-md-up} {
            margin-top: 20px;
        }
    }
}
.container--ad-naviboard {
    display: block !important;
}

/* HEADE-main
-------------------------------------------------------------- */
.header-main {
    .container--ad-naviboard {
        min-height: 120px;

        @media #{$media-xs} {
            min-height: 75px;
        }
    }
}


/* LEADBOARD
-------------------------------------------------------------- */

.category-header #div-gpt-ad-LEADBOARD-0 {
    @media #{$media-sm} {
        margin-left: -15px;
        margin-right: -15px;
    }

    @media #{$media-md} {
        margin-left: -12px;
        margin-right: -12px;
    }

    @media #{$media-md-up} {
        margin-bottom: -15px;

        > div {
            margin-bottom: 0;
        }
    }
}

/* WIDEBOARD
-------------------------------------------------------------- */

.homepage #div-gpt-ad-WIDEBOARD-0 {
    @media #{$media-sm} {
        margin-left: -15px;
        margin-right: -15px;
    }

    > div {
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* RECTANGLE
-------------------------------------------------------------- */

#div-gpt-ad-RECTANGLE-0 {
    img,
    iframe {
        margin: 0 auto;
    }
}

.homepage #div-gpt-ad-RECTANGLE-0 {
    > div {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

.catalog-category-view {
    #div-gpt-ad-RECTANGLE-0 {
        margin-top: 18px;
        position: relative;
        width: 300px;
        margin: 0 auto;

        @media #{$media-to-sm} {
            margin-bottom: -25px;
            width: auto;
            left: 0;
        }

        > div {
            @media #{$media-to-sm} {
                margin-bottom: 0;
            }
        }
    }

    &.category-leki-na-recepte {
        #div-gpt-ad-RECTANGLE-0 {
            @media #{$media-xs} {
                margin-bottom: -15px;
            }

            > div {
                @media #{$media-xs} {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.catalog-category-view,
.catalogsearch-result-index {
    .catalog-ad {
        .dfpAd {
            min-height: 125px;

            @media #{$media-xs} {
                min-height: 75px;
            }

            @media #{$media-md-up} {
                margin-top: 0;
                max-height: 150px;
                margin-bottom: 20px;
            }

            > div {
                @media #{$media-md-up} {
                    margin-top: 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.catalogsearch-result-index {
    .container--ad-naviboard {
        display: none !important; //overwrite styles for serch results page
    }
}

.aside--left {
    #div-gpt-ad-RECTANGLE-0 {
        @media #{$media-md} {
            width: 273px;
            margin-left: -35px;
        }

        @media #{$media-lg} {
            width: 300px;
            left: -25px;
            //margin-left: -35px;
        }
    }

    .block-layered-nav + #div-gpt-ad-RECTANGLE-0 {
        > div {
            @media #{$media-md-up} {
                margin-top: -80px;
            }
        }
    }
}

.cms-content .aside--left #div-gpt-ad-RECTANGLE-0,
.article-page #div-gpt-ad-RECTANGLE-0 {
    margin-left: auto;
    margin-right: auto;

    @media #{$media-xs} {
        margin-left: 0 !important;
    }
}

.cms-content .aside--left #div-gpt-ad-RECTANGLE-0 {
    @media #{$media-xs} {
        margin-bottom: -5px;
    }

    @media #{$media-sm} {
        margin-bottom: -20px;
    }

    > div {
        @media #{$media-to-sm} {
            margin-bottom: 0;
        }
    }
}

.article-page #div-gpt-ad-RECTANGLE-0 {
    > div {
        @media #{$media-to-sm} {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.cms-content .homepage .aside--left #div-gpt-ad-RECTANGLE-0 {
    > div {
        @media #{$media-xs} {
            margin-bottom: 20px;
        }
    }
}

.cms-content .article-page .aside--right {
    @media #{$media-md} {
        padding-left: 30px;
    }

    &::before {
        @media #{$media-md} {
            left: 10px;
        }
    }
}

.cms-content .topic-page .aside--left #div-gpt-ad-RECTANGLE-0 {
    @media #{$media-sm} {
        margin-bottom: -10px;
    }

    > div {
        @media #{$media-sm} {
            margin-bottom: 0;
        }
    }
}

.product-content__lists #div-gpt-ad-RECTANGLE-0 {
    @media #{$media-sm} {
        margin-bottom: -10px;
    }

    @media #{$media-md-up} {
        margin-top: -40px;
    }

    > div {
        @media #{$media-sm} {
            margin-bottom: 0;
        }

        @media #{$media-md-up} {
            margin-top: 0;
        }
    }
}

.search #div-gpt-ad-RECTANGLE-0 {
    > div {
        margin-bottom: 20px;
    }
}

/* ADBOARD
-------------------------------------------------------------- */

.homepage,
.topic-page {
    #div-gpt-ad-ADBOARD-A-0,
    #div-gpt-ad-ADBOARD-B-0 {
        @media #{$media-sm-up} {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    #div-gpt-ad-ADBOARD-A-0 {
        > div {
            margin-bottom: 20px;
        }
    }

    .main-color-boxes {
        #div-gpt-ad-ADBOARD-A-0,
        #div-gpt-ad-ADBOARD-B-0 {
            > div {
                margin-top: 0;
            }
        }

        #div-gpt-ad-ADBOARD-A-0 {
            > div {
                margin-bottom: 30px;
            }
        }

        #div-gpt-ad-ADBOARD-B-0 {
            > div {
                margin-bottom: 0;
            }
        }
    }
}

.topic-page {
    #div-gpt-ad-ADBOARD-A-0 {
        > div {
            margin-top: 0;
            margin-bottom: 25px;

            @media #{$media-xs} {
                margin-top: -5px;
                margin-bottom: 20px;
            }
        }
    }

    .main-color-boxes {
        #div-gpt-ad-ADBOARD-A-0 {
            > div {
                margin-bottom: 25px;
            }
        }
    }
}

.topic-page__articles {
    #div-gpt-ad-ADBOARD-A-0 {
        > div {
            margin-top: 0;
        }
    }

    + #div-gpt-ad-ADBOARD-B-0 {
        > div {
            @media #{$media-md-up} {
                margin-top: -10px;
            }
        }
    }
}

/* CONTENTBOARD
-------------------------------------------------------------- */

.article-page #div-gpt-ad-CONTENTBOARD-0 {
    @media #{$media-sm} {
        width: 750px;
        margin-left: -15px;
    }

    @media #{$media-lg} {
        width: 750px;
        margin-left: -10px;
    }

    > div {
        margin-top: 0;
        margin-bottom: 0;
    }
}
